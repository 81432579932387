<!--/**-->
<!--* 自动生成 vue列表代码【模块名：仓库】-->
<!--* 生成日期：2024/04/24 22:26:33-->
<!--* 生成路径: src/pages/warehouse/WarehouseList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <div class='table-search'>
          <el-form :model='searchCondition' inline size='mini'>
            <el-form-item label='仓库名称'>
              <el-input v-model='searchCondition.name' placeholder='模糊搜索' clearable></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading' @click='handleQuery'>搜索</el-button>
              <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!--        操作栏-->
<!--        <el-row class='mb8' :gutter='10'>-->
<!--          <el-col :span='1.5'>-->
<!--            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>-->
<!--          </el-col>-->
<!--          <el-col :span='1.5'>-->
<!--            <el-button icon='el-icon-upload' @click='openImport=true'>导入</el-button>-->
<!--          </el-col>-->
<!--          <el-col :span='1.5'>-->
<!--            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("warehouse_export")' @export='handleExport'></export-btn>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <!--    渲染表格-->
        <div class='default-table'>
          <MlPageTable :all-columns='defaultColumns'
                       :table-name='tableName'
                       :data-list='dataList'
                       :limit.sync='pageData.page_size'
                       :page.sync='pageData.current_page'
                       :total.sync='total'
                       :height='height'
                       highlight-current-row
                       :row-class-name='tableRowClassName'
                       @row-click='rowClickEvent'
                       @getList='getList'>
            <el-table-column align='center' width='200' label='操作'>
              <template slot-scope='{row,$index}'>
                <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'>修改</el-button>
                <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
                <el-button type='text' icon='el-icon-s-grid' @click="jumpLocationPage(row)" :disabled="row.state === 0">库位管理</el-button>
              </template>
            </el-table-column>
          </MlPageTable>
        </div>
      </div>

      <!-- 添加或修改对话框 -->
      <el-dialog :title='title' :visible.sync='open' width='800px' append-to-body :close-on-click-modal='false'>
        <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
          <el-form-item label='仓库名称' prop='name'>
            <el-input v-model='form.name' placeholder='请输入仓库名称' clearable/>
          </el-form-item>
          <el-form-item label='地址' prop='address'>
            <el-input v-model='form.address' placeholder='请输入地址' clearable/>
          </el-form-item>
          <el-form-item label='负责人' prop='leader_name'>
            <el-input v-model='form.leader_name' placeholder='请输入负责人' clearable/>
          </el-form-item>
          <el-form-item label='联系电话' prop='phone'>
            <el-input v-model='form.phone' placeholder='请输入联系电话' clearable/>
          </el-form-item>
          <el-form-item label='状态' prop='state'>
            <el-select v-model='form.state' placeholder='请选择状态' clearable>
              <el-option :label='option.label' :value='option.value' :key='key' v-for='(option, key) in stateOptions'></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label='备注' prop='remark'>
            <el-input v-model='form.remark' placeholder='请输入备注' clearable/>
          </el-form-item>

        </el-form>
        <div slot='footer' class='dialog-footer'>
          <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
          </el-button>
          <el-button @click='cancel'>取 消</el-button>
        </div>
      </el-dialog>
      <!--      导入-->
      <ImportWarehouse :visible.sync='openImport' v-if='hasAuthority("logistic_subscribe_import")' @imported='handleQuery' />
    </PageHeaderLayout>
  </div>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportWarehouse from './ImportWarehouse'
import { jumpPage } from '@/utils/utils'

export default {
  name: 'WarehouseList',
  components: { ImportWarehouse, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'warehouse',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      height: 800,
      searchCondition: { date_range: [],wh_code: null, name: null, address: null, leader_name: null, phone: null, remark: null, cover_indoor: null, state: null, created_by: null, updated_by: null},
      pageData: { current_page: 1, page_size: 100 },
      orderBy: { id: 'asc' },
      stateOptions: {
        0: { label: '禁用', value: 0, color_value: '#f56c6c' },
        1: { label: '启用', value: 1, color_value: '#67c23a' }
      },
      pickerOptions: {
        shortcuts: [{
          text: '本月',
          onClick(picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: '今年至今',
          onClick(picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近六个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      defaultColumns:  [{ hide: false, prop: 'wh_code', label: '仓库编号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'name', label: '仓库名称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'address', label: '地址', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'phone', label: '联系电话', width: 120, sortable: true, fixed: false },
        { hide: false, prop: '_state', label: '状态', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 120, sortable: true, fixed: false }],
      // 弹出层标题
      title: '添加/修改-仓库',
      // 是否显示弹出层
      open: false,
      // 表单校验
      rules:{name: [{ required: true, message: '仓库名称不能为空', trigger: 'blur' }],
        address: [{ message: '地址不能为空', trigger: 'blur' }],
        leader_name: [{ message: '负责人不能为空', trigger: 'blur' }],
        phone: [{ message: '联系电话不能为空', trigger: 'blur' }],
        remark: [{ message: '备注不能为空', trigger: 'blur' }],
        state: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
        cover_indoor: [{ message: '仓库室内图片ID不能为空', trigger: 'blur' }]},
      // 表单参数
      form: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey:'page_search_key:warehouse'
    }
  },
  methods: {
    jumpPage,
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getWarehouseList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteWarehouse(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-仓库'
      // let { info } = await this.$api.getWarehouseInfo(row.id)
      this.form = {}
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-仓库'
      // let { info } = await this.$api.getWarehouseInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveWarehouse(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportWarehouses(searchCondition)
        let name = `仓库`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    //跳转到库位管理
    jumpLocationPage(row) {
      if (row.id) {
        this.$router.push('/warehouse_location/list?wh_id=' + row.id);
      }
    },
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    this.handleQuery()
  }
}
</script>

<style scoped>

</style>